import { AppConfig } from '@/utils/AppConfig';
import {
    LIMIT_NUMBER_OF_BLOGS,
    LOCATIONS_DISTANCE,
  } from '@/utils/constants';

export const GOOGLE_CLIENT_REVIEWS = `${process.env.DATA_VAULT_API_DOMAIN}/reviews/${AppConfig.brand}`;
export const CONVENIENT_LOCATIONS = `${process.env.DATA_VAULT_API_DOMAIN}/homepageNearestLocations/`;
export const INNER_PAGES = `${process.env.CMS_API_DOMAIN}/inner-pages?filters[Slug][$eq]=about-us&populate[Section][populate][Background_Image][populate]=*&populate[Section][populate][Cards][populate]=*&populate[Section][populate][Logos][populate]=*&populate[Section][populate][Avatar][populate]=*&populate[Section][populate][Featured_Image][populate]=*&populate[Section][populate][Image][populate]=*&populate[SEO][populate][Open_Graph][populate]=*&populate[Section][populate][Units][populate]=*&populate[Section][populate][Link][populate]=*&populate[Section][populate][Internal_Link][populate]=*&populate[Section][populate][External_Link][populate]=*&populate[Section][populate]=*`;
export const BLOG_BY_SLUG = `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=se&filters[Slug][$eq]`;
export const ADDITIONAL_BLOGS = `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=se&populate=*&locale=en`;
export const GET_BLOG_DATA = `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=se&populate=*&locale=en&filters[Categories][Slug][$eq]`;
export const GET_OUR_BRANDS = `${process.env.CMS_API_DOMAIN}/our-brands?populate[Logo][populate]=*&locale=en&sort=Order`;

export const PARQ_API = `${process.env.PARQ_URL}`;
export const CONTACT_POST_API = `${process.env.CALLBACK_API_DOMAIN}/contact-us/${AppConfig.brand_name}`;
export const REFERRAL_POST_API = `${process.env.CALLBACK_API_DOMAIN}/referral`;

export const BOOK_POST_API = `${process.env.CALLBACK_API_DOMAIN}/valet`;

export const SMALL_BUSINESS_POST_API = `${process.env.CALLBACK_API_DOMAIN}/small-business/${AppConfig.brand_name}`;

export const TEAM_CANADA_POST_API = `${process.env.CALLBACK_API_DOMAIN}/team-canada/${AppConfig.brand_name}`;

export const CHAT_BOT_CALLBACK_EXISTING_CLIENT_API = `${process.env.DATA_VAULT_API_CASE_MANAGEMENT}`;

export const CHAT_BOT_CALLBACK_CONTACT_UPDATE_API = ``;

const getcallMebackUrl = (formType: any, storeType: any) => {
  return `${process.env.CALLBACK_API_DOMAIN}/${storeType}/${AppConfig.brand_name}/${formType}`;
};

const getBrandLocaleUrl = (brand: any = 'se', locale: any = 'en') => {
  return `${process.env.CMS_API_DOMAIN}/brands?filters[Slug][$eq]=${brand}&populate[Logo][populate]=*&populate[Favicon][populate]=*&populate[Canada_Logo][populate]=*&populate[Shop_Store][populate][Title_Image]=*&populate[Timings][populate]=*&populate[Social_Media][populate]=*&populate[Header_Settings][populate]=*&populate[Units][populate][Thumbnail]=*&populate[Blog][populate][Thumbnail][populate]=*&populate[CTA_Section][populate]=*&populate=localizations&populate[secondary_logo][populate]=*&locale=${locale}`;
};

const getHomeLocaleUrl = (brand: any = 'se', locale: any = 'en') => {
  return `${process.env.CMS_API_DOMAIN}/home-pages?filters[Brands][Slug][$eq]=${brand}&populate[Section][populate][Background_Image][populate]=*&populate[Section][populate][Cards][populate]=*&populate[Section][populate][Logos][populate]=*&populate[Section][populate][Avatar][populate]=*&populate[Section][populate][Featured_Image][populate]=*&populate[Section][populate][Image][populate]=*&populate[SEO][populate][Open_Graph][populate]=*&populate[Section][populate][Units][populate]=*&populate[Section][populate][Link][populate]=*&populate[Section][populate][Internal_Link][populate]=*&populate[Section][populate][External_Link][populate]=*&populate[Section][populate]=*&populate=localizations&locale=${locale}`;
};

const getInnerPagesUrl = (
  innerPage: any,
  brand: any = 'se',
  locale: any = 'en'
) => {
  return `${process.env.CMS_API_DOMAIN}/inner-pages?filters[Slug][$eq]=${innerPage}&filters[Brands][Slug][$eq]=${brand}&locale=${locale}&populate[Section][populate][Cards][populate]=*&populate[Section][populate][Background_Image][populate]=*&populate[Section][populate][Logos][populate]=*&populate[Section][populate][Avatar][populate]=*&populate[Section][populate][Featured_Image][populate]=*&populate[Section][populate][Image][populate]=*&populate[SEO][populate][Open_Graph][populate]=*&populate[Section][populate][Units][populate]=*&populate[Section][populate][Link][populate]=*&populate[Section][populate][Internal_Link][populate]=*&populate[Section][populate][External_Link][populate]=*&populate[Section][populate][Price_Card][populate]=*&populate[Section][populate][Timings][populate]=*&populate[Section][populate][Panel][populate]=*&populate[Section][populate][Cards_with_LCode][populate]=*&populate[Section][populate][Accordian][populate]=*&populate[Section][populate]=*&populate[localizations][populate]=*`;
};

const getArticles = (
  brand: any = 'se',
  pageSize: any = '3',
  locale: any = 'en',
  category: any = 'moving'
) => {
    return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&pagination[pageSize]=${pageSize}&pagination[page]=1&populate=*&locale=${locale}&filters[Categories][Slug][$eq]=${category}&sort=publishedAt:DESC`;
};

const getArticlesSortByFeatured = (
    brand: string,
    pageSize: number,
    locale: any = 'en',
    category: any = 'footprint'
) => {
    return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&pagination[pageSize]=${pageSize}&pagination[page]=1&populate=*&locale=${locale}&filters[Categories][Slug][$eq]=${category}&sort=Featured:desc&sort=publishedAt:desc`;
};

const getBlogBySlug = (brand: any, blogName: any, locale: any) => {
  return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&filters[Slug][$eq]=${blogName}&populate=*&locale=${locale}`;
};

// const getAdditionalBlogs = (brand: any, locale: any) => {
//   return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&populate=*&locale=${locale}&sort=publishedAt:DESC`;
// };

// const getBlogInfo = (brand: any, locale: any, tabName: any) => {
//   return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&populate=*&locale=${locale}&filters[Categories][Slug][$eq]=${tabName}&sort=publishedAt:DESC`;
// };


const getBlogsByFeatured = (
    brand: string,
    locale: any,
    category: any,
    featured: boolean,
    start: number = 0,
    limit: number = LIMIT_NUMBER_OF_BLOGS
  ) => {
    return `${process.env.CMS_API_DOMAIN}/blogs?filters[Brands][Slug]=${brand}&filters[Featured]=${featured}&pagination[pageSize]=${limit}&pagenation[start]=${start}&pagination[page]=1&filters[Categories][Slug][$eq]=${category}&populate=*&locale=${locale}&sort=publishedAt:DESC`;
  };

const getFaqInfo = (brand: any, storageName: any, locale: any) => {
  return `${process.env.CMS_API_DOMAIN}/faqs?filters[Brands][Slug]=${brand}&filters[FAQ_Category][Slug][$eq]=${storageName}&populate=*&locale=${locale}`;
};

const getNearestStoreLocator = (
  brand: any = 'se',
  locale: any = 'fr',
  lat: any = null,
  lng: any = null,
  originalat?: any,
  originallng?: any,
  distance?: any
) => {
  /* eslint-disable no-param-reassign */
  originalat = originalat || lat;
  originallng = originallng || lng;
  distance = distance || LOCATIONS_DISTANCE;
  return `${process.env.DATA_VAULT_API_DOMAIN}/nearestLocations/?lat=${lat}&lng=${lng}&distance=${distance}&lang=${locale}&brand=${brand}&originlat=${originalat}&originlng=${originallng}`;
};


const getLandingPage = (location: any = 'se', locale: any = 'fr') => {
  return `${process.env.DATA_VAULT_API_DOMAIN}/locationMeta?slug=${location}&lang=${locale}`;
};

const getDealsBySlug = (dealName: any, brand: any, locale: any) => {
  return `${process.env.CMS_API_DOMAIN}/deals?filters[Slug][$eq]=${dealName}&filters[Brands][Slug]=${brand}&populate[Thumbnail][populate]=*&populate=localizations&locale=${locale}&populate[SEO][populate][Open_Graph][populate]=*`;
};

const getDealsInfo = (brand: any, locale: any) => {
  return `${process.env.CMS_API_DOMAIN}/deals?filters[Brands][Slug]=${brand}&populate[Thumbnail][populate]=*&populate=localizations&locale=${locale}&populate[SEO][populate][Open_Graph][populate]=*`;
};
const getClientReviews = (
  lcode: any = 'L130'
) => {
  return `${process.env.DATA_VAULT_API_DOMAIN}/reviews/${lcode}`;
};

const getClientReview = (
  lcode: any = 'L130',
  locale: any = 'fr'
) => {
  return `${process.env.DATA_VAULT_API_DOMAIN}reviews?lcode=${lcode}&lang=${locale}`;
};

const getFacilitiesInfo = (
  location: any,
  brand: any,
  locale: any,
  facilityLen: any
) => {
  return `${process.env.CMS_API_DOMAIN}/facilities?filters[${
    facilityLen === 2 ? 'Slug' : 'City_Slug'
  }][$eq]=${location}&filters[Brands][Slug][$eq]=${brand}&populate[Facility_Gallery][populate][Image]=*&populate[CTA_Section][populate]=*&populate[Store_Hours]=*&populate[Open_Hours]=*&populate[Support]=*&populate[City][populate]=*&populate[Has_Features]=*&populate[Features]=*&populate[Address]=*&locale=${locale}&populate=localizations`;
};

const getFacilitiesInfoOffice = (facilityID: any, brand: any, locale: any) => {
  return `${process.env.CMS_API_DOMAIN}/facilities?filters[Facility_ID][$eq]=${facilityID}&filters[Brands][Slug][$eq]=${brand}&fields=Title&populate[Address]=*&locale=${locale}&populate=localizations`;
};

const getFacilitiesInfoSEO = (
  location: any,
  brand: any,
  locale: any,
  facilityLen: any
) => {
  return `${process.env.CMS_API_DOMAIN}/facilities?filters[${
    facilityLen === 2 ? 'Slug' : 'City_Slug'
  }][$eq]=${location}&filters[Brands][Slug][$eq]=${brand}&populate[Facility_Gallery][populate][Image]=*&populate[CTA_Section][populate][background_Image]=*&populate[Store_Hours]=*&populate[Open_Hours]=*&populate[Support]=
  *&populate[Has_Features]=*&populate[Province][fields][0]=Name&populate[City][populate][Blog][populate][Thumbnail][populate]=*&populate[Address]=*&populate[Features]=*&populate[Blog][populate][Thumbnail]=*&populate[SEO][populate][Open_Graph][populate]=*&locale=${locale}&populate=localizations`;
};

const getUnitsData = (lcode: any = 'L130', locale: any = 'en') => {
  return `${process.env.DATA_VAULT_API_DOMAIN}/units?lcode=${lcode}&lang=${locale}`;
};

const getRightUnitInfo = (brand: any = 'se', locale: any = 'en') => {
  return `${process.env.CMS_API_DOMAIN}/brands?populate=localizations&locale=${locale}&filters[Slug][$eq]=${brand}&populate[Shop_Store][populate][Title_Image]=*&populate[Storage_Units][populate][Units][populate][Thumbnail][populate]=*&populate[CTA_Section][populate]=*`;
};

const getCityLandingInfo = (
  slug: any,
  brand: any = 'se',
  locale: any = 'en'
) => {
  return `${process.env.CMS_API_DOMAIN}/city-landing-pages?filters[Slug][$eq]=${slug}&filters[Brands][Slug][$eq]=${brand}&populate[CTA_Section][populate]=*&populate[Cards][populate][Cards][populate][Thumbnail]=*&populate[FAQs][populate]=*&populate[Facilities][populate]=*&populate[Quick_Searches][populate]=*&locale=${locale}&populate=localizations`;
};

const getVehicleLandingInfo = (
  slug: any,
  brand: any = 'se',
  locale: any = 'en'
) => {
  if (slug.includes('etudiant')) {
    // hotfix for French student city landing pages
    slug = slug.replace('-fr', '');
  }
  return `${process.env.CMS_API_DOMAIN}/vehicle-landing-pages?filters[Slug][$eq]=${slug}&filters[Brands][Slug][$eq]=${brand}&populate[CTA_Section][populate]=*&populate[Cards][populate][Cards][populate][Thumbnail]=*&populate[FAQs][populate]=*&populate[Facilities][populate]=*&populate[Quick_Searches][populate]=*&locale=${locale}&populate=localizations&populate[Pre_Filter][populate]=*`;
};

const getVehicleSEOInfo = (
  slug: any,
  brand: any = 'se',
  locale: any = 'en'
) => {
  slug = locale === 'en'? slug : `${slug}-fr`;
  if (slug.includes('etudiant')) {
    // hotfix for French student city landing pages
    slug = slug.replace('-fr', '');
  }
  return `${process.env.CMS_API_DOMAIN}/vehicle-landing-pages?filters[Slug][$eq]=${slug}&filters[Brands][Slug][$eq]=${brand}&locale=${locale}&populate[SEO][populate][Open_Graph][populate]=*&populate[FAQs][populate]=*&populate=localizations`;
};

const getProviStore = (
  provinance: any = 'ontario',
  locale: any = 'en',
  brand: any = 'se'
) => {
  return `${process.env.CMS_API_DOMAIN}/facilities?filters[Brands][Slug][$eq]=${brand}&filters[Province][Slug][$eq]=${provinance}&fields=Title,Slug,Sitelink_URL_ID&populate=Address&locale=${locale}`;
};

const getAllProvinces = (locale: any = 'en', brand: any = 'se') => {
  return `${process.env.CMS_API_DOMAIN}/provinces?locale=${locale}&populate[Facilities][fields]=Title,Slug,Sitelink_URL_ID,Facility_ID&populate[Facilities][sort]=Title&populate[Facilities][populate][Address][populate]=%2A&populate[Facilities][populate][Brands][fields]=Site_Title,Slug&filters[Facilities][id][$notNull]=true&filters[Facilities][Brands][Slug]=${brand}&sort=Name`;
};

const getStudentSpecialUrl = (
  defaultLink: any,
  locale: any = 'en',
  brand: any = 'se'
) => {
  return `${process.env.CMS_API_DOMAIN}/contests?filters[Slug][$eq]=${defaultLink}&filters[Brands][Slug]=${brand}&populate[Thumbnail][populate]=*&populate[Section][populate][Logos][populate]=*&populate[Promo_Badge_Image][populate]=*&locale=${locale}`;
};
const getValidateCity = (slug: any, brand: any) => {
  return `${process.env.CMS_API_DOMAIN}/city-landing-pages?filters[Slug][$eq]=${slug}&filters[Brands][Slug][$eq]=${brand}&populate[SEO][populate][Open_Graph][populate]=*&populate[FAQs][populate]=*&fields=Title,Slug,locale&populate[localizations][fields]=Title,Slug,locale&populate=*&locale=en`;
};

export {
  getAllProvinces,
  getArticles,
  getArticlesSortByFeatured,
  getBlogBySlug,
  getBlogsByFeatured,
  getBrandLocaleUrl,
  getcallMebackUrl,
  getCityLandingInfo,
  getClientReview,
  getClientReviews,
  getDealsBySlug,
  getDealsInfo,
  getFacilitiesInfo,
  getFacilitiesInfoOffice,
  getFacilitiesInfoSEO,
  getFaqInfo,
  getHomeLocaleUrl,
  getInnerPagesUrl,
  getLandingPage,
  getNearestStoreLocator,
  getProviStore,
  getRightUnitInfo,
  getStudentSpecialUrl,
  getUnitsData,
  getValidateCity,
  getVehicleLandingInfo,
  getVehicleSEOInfo
};
